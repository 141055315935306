import { ref, watch } from '@vue/composition-api'
import {
  mdiPageFirst,
  mdiPageLast,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import store from '@/store'
import channelCodeEnum from '@/constants/channel-code'

export default function usePaymentMethodList() {
  const paymentMethodItems = ref([])
  const paymentMethodItemsLength = ref(0)

  const tableColumns = [
    {
      text: 'UID', value: 'methodCode', sortable: false, align: 'center',
    },
    { text: 'NAME', value: 'methodName' },
    {
      text: 'COUNTRY', value: 'countryName', sortable: false, align: 'center',
    },
    {
      text: 'CHANNEL', value: 'channelCode', sortable: false, align: 'center',
    },
    {
      text: 'RATES', value: 'formattedMerchantDiscountRateConfig', sortable: false, align: 'center',
    },
    {
      text: 'APPLY TO CUSTOMER',
      value: 'merchantDiscountRateConfig',
      align: 'center',
      sortable: false,
      width: 100,
    },
    {
      text: 'CURRENCIES / LIMITS', value: 'currencies', sortable: false, align: 'center',
    },
    {
      text: 'STATUS', value: 'isActive', sortable: false, align: 'center',
    },
  ]

  const shopFilterModel = ref(null)
  const shopFilterItems = ref([])
  const countryFilterModel = ref(null)
  const countryFilterItems = ref([])
  const channelFilterModel = ref(null)
  const channelFilterItems = ref([])
  const searchFilterModel = ref(null)

  const loading = ref(false)
  const loaders = ref({})

  const options = ref({
    sortBy: ['methodName'],
    sortDesc: [false],
  })

  const footerTableProps = {
    showFirstLastPage: true,
    itemsPerPageOptions: [10, 20, 50, 100],
    showCurrentPage: true,
    firstIcon: mdiPageFirst,
    lastIcon: mdiPageLast,
    prevIcon: mdiChevronLeft,
    nextIcon: mdiChevronRight,
  }

  const itemsPerPage = ref(footerTableProps[0])

  // fetch data
  const fetchList = () => {
    loading.value = true

    store
      .dispatch('processing-payment-methods/fetchList', {
        options: options.value,
        shopId: shopFilterModel.value,
        countryCode: countryFilterModel.value,
        channelCode: channelFilterModel.value,
      })
      .then(response => {
        const { records, count } = response.data
        paymentMethodItems.value = records
        paymentMethodItemsLength.value = count
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  const searchByKeyword = () => {
    options.value.page = 1

    loading.value = true

    store
      .dispatch('processing-payment-methods/fetchList', {
        search: searchFilterModel.value,
        options: options.value,
      })
      .then(response => {
        const { records, count } = response.data
        paymentMethodItems.value = records
        paymentMethodItemsLength.value = count
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  watch([options], () => {
    fetchList()
  })

  const applyFilters = () => {
    options.value.page = 1
    fetchList()
  }

  const resetFilters = () => {
    searchFilterModel.value = null
    shopFilterModel.value = null
    countryFilterModel.value = null
    channelFilterModel.value = null
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('processing-payment-methods/fetchConfig')
      .then(response => {
        const config = response.data
        shopFilterItems.value = config.shops
        countryFilterItems.value = config.countries
        channelFilterItems.value = config.channels
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  const applyToCustomer = item => {
    loaders.value.applyToCustomer = true
    store
      .dispatch('processing-payment-methods/applyToCustomer', { id: item.shopPaymentRateConfigId, value: item.merchantDiscountRateConfig.applyToCustomer })
      .then(response => {

      })
      .catch(error => error).finally(() => {
        loaders.value.applyToCustomer = false
      })
  }

  const exportList = () => {
    loading.value = true
    store
      .dispatch('processing-payment-methods/exportList', {
        search: searchFilterModel.value,
        options: options.value,
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = response.headers.filename
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusVariant = isActive => {
    if (isActive) return 'success'

    return 'error'
  }

  const resolvePaymentMethodImageUrl = imageName => `${process.env.VUE_APP_AWS_S3_PAYMENT_METHODS}/${imageName}`

  const resolveChannel = channelCode => {
    if (channelCode === channelCodeEnum.CASH) return 'success'
    if (channelCode === channelCodeEnum.ONLINE) return 'warning'
    if (channelCode === channelCodeEnum.CRYPTO) return 'primary'
    if (channelCode === channelCodeEnum.CREDIT_CARD) return 'error'

    return 'success'
  }

  const resolveStatus = isActive => {
    if (isActive) return 'success'

    return 'error'
  }

  return {
    tableColumns,
    paymentMethodItems,
    paymentMethodItemsLength,
    searchFilterModel,
    shopFilterModel,
    shopFilterItems,
    countryFilterModel,
    countryFilterItems,
    channelFilterModel,
    channelFilterItems,
    loading,
    loaders,
    options,
    footerTableProps,
    itemsPerPage,
    applyToCustomer,
    applyFilters,
    resetFilters,
    fetchList,
    fetchConfig,
    searchByKeyword,
    resolveStatusVariant,
    resolvePaymentMethodImageUrl,
    resolveChannel,
    resolveStatus,
    exportList,
  }
}
