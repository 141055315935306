<template>
  <div id="administration-payments">
    <!-- list filters -->
    <v-card>
      <v-card-title>Payment Methods</v-card-title>

      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="shopFilterModel"
            placeholder="Shop"
            :items="shopFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="countryFilterModel"
            placeholder="Country"
            :items="countryFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="channelFilterModel"
            placeholder="Channel"
            :items="channelFilterItems"
            item-text="text"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loading"
          @click.prevent="applyFilters"
        >
          Apply Filters
        </v-btn>

        <v-btn
          color="secondary"
          outlined
          class="mb-4"
          type="reset"
          @click.prevent="resetFilters"
        >
          Reset
        </v-btn>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-text-field
            v-model="searchFilterModel"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            class="mb-4 me-3 searchBy"
            @keyup.enter="searchByKeyword"
          ></v-text-field>

          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-0"
            :disabled="paymentMethodItems == 0"
            :loading="loading"
            @click="exportList"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="paymentMethodItems"
        :options.sync="options"
        :server-items-length="paymentMethodItemsLength"
        :items-per-page="itemsPerPage"
        :loading="loading"
        show-current-page
        loading-text="Loading... Please wait"
        :footer-props="footerTableProps"
      >
        <!-- UID -->
        <template #[`item.legacyCode`]="{ item }">
          <!-- toma el props del componente hijo del slot -->
          <div class="d-flex justify-center">
            {{ item.legacyCode }}
          </div>
        </template>

        <template #[`item.legacyName`]="{ item }">
          <div class="d-flex align-center">
            <span
              class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.legacyName }}</span>
          </div>
        </template>

        <template #[`item.imageName`]="{ item }">
          <div class="d-flex align-center">
            <img
              class="payment-method-img"
              :src="resolvePaymentMethodImageUrl(item.imageName)"
            />
          </div>
        </template>

        <template #[`item.formattedMerchantDiscountRateConfig`]="{ item }">
          <div>
            {{ item.formattedMerchantDiscountRateConfig }}
          </div>
        </template>

        <template #[`item.merchantDiscountRateConfig`]="{ item }">
          <div class="d-flex justify-center">
            <v-switch
              v-model="item.merchantDiscountRateConfig.applyToCustomer"
              class="d-flex justify-center"
              @change="applyToCustomer(item)"
            />
          </div>
        </template>

        <template #[`item.currencies`]="{ item }">
          <div
            v-for="cd in item.currencies"
            :key="cd.code"
          >
            <div>
              <code>{{ cd.code }}</code>: <code>Max: {{ cd.max }}</code> <code>Min: {{ cd.min }}</code>
            </div>
          </div>
        </template>

        <template #[`item.channelCode`]="{ item }">
          <v-chip
            small
            :color="resolveChannel(item.channelCode)"
            :class="`${resolveChannel(item.channelCode)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.channelCode }}
          </v-chip>
        </template>

        <template #[`item.isActive`]="{ item }">
          <v-avatar
            :color="resolveStatus(item.isActive)"
            :class="`v-avatar-light-bg ${resolveStatus(item.isActive)}--text`"
            size="32"
          >
            <v-icon
              size="16"
              :color="item.isActive ? 'sucess' : 'error'"
            >
              {{
                item.isActive
                  ? icons.mdiCheckCircleOutline
                  : icons.mdiAlertCircleOutline
              }}
            </v-icon>
          </v-avatar>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// sidebar
import {
  mdiMagnify,
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
} from '@mdi/js'
import { onMounted, onUnmounted } from '@vue/composition-api'
import { avatarText, formatDate, formatCurrency } from '@core/utils/filter'
import store from '@/store'
import storeModule from '../storeModule'
import usePaymentMethodsList from './usePaymentMethodsList'

export default {
  setup() {
    const STORE_MODULE_NAME = 'processing-payment-methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const {
      tableColumns,
      paymentMethodItems,
      paymentMethodItemsLength,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      countryFilterModel,
      countryFilterItems,
      channelFilterModel,
      channelFilterItems,
      loading,
      loaders,
      options,
      footerTableProps,
      itemsPerPage,
      applyToCustomer,
      fetchList,
      searchByKeyword,
      applyFilters,
      resetFilters,
      fetchConfig,
      resolveStatusVariant,
      resolvePaymentMethodImageUrl,
      resolveChannel,
      resolveStatus,
      exportList,
    } = usePaymentMethodsList()

    onMounted(() => {
      fetchConfig()
    })

    return {
      tableColumns,
      paymentMethodItems,
      paymentMethodItemsLength,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      countryFilterModel,
      countryFilterItems,
      channelFilterModel,
      channelFilterItems,
      loading,
      loaders,
      options,
      footerTableProps,
      itemsPerPage,
      applyToCustomer,
      avatarText,
      resolveStatusVariant,
      resolvePaymentMethodImageUrl,
      resolveChannel,
      resolveStatus,
      fetchList,
      searchByKeyword,
      applyFilters,
      resetFilters,
      fetchConfig,
      formatDate,
      formatCurrency,
      exportList,

      // icons
      icons: {
        mdiMagnify,
        mdiAlertCircleOutline,
        mdiCheckCircleOutline,
      },
    }
  },
}
</script>

<style scoped>
.payment-method-img {
  height: 25pt;
}

.searchBy {
  width: 380px;
}
</style>
